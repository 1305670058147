import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import './css/App.css';
import image from './images/under-construction-mouse.png';
import tech  from './images/tech.png';
import config from './config/main';

function App() {
  return (
        <div className="App-header">
            <Container>
                <Row>
                    <Col sm={4}>
                        <img src={image} alt={'Under Construction'} style={{width: '100%'}}/>
                    </Col>
                    <Col>
                        <div style={{marginTop: '100px'}}>
                        <h1>Welcome to our {config.websiteName}</h1>
                        <h2>We are currently working on something amazing behind the scenes.</h2>
                        <p>Please check back later!</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={{fontSize: '12px', textAlign: 'left', marginTop: '30px'}}>Website by <a href={'https://www.dankershaw.com'} target={'_blank'} rel={"noopener noreferrer"}>Dan K</a> <img src={tech} alt={'Website by Dan K'} style={{height: '20px'}}/></p>
                    </Col>
                </Row>
        </Container>
      </div>
  );
}

export default App;
